import { useStores } from "stores";

import { ReturnReleaseFormState, useTertiaryFormState } from "components/ReleaseForm/Hooks";
import { ApiKeyFields, Comment, CommentForm, ReleaseStatus, SecondaryFormNames, TertiaryFormNames } from "types";
import { convertSnakeToCamel } from "utils";

interface WorkListFormStateCbProps {
  secondaryFormName: SecondaryFormNames;
  secondaryFormId: string;
  tertiaryFormName: TertiaryFormNames;
  tertiaryFormId: string;
  filterCommentsCb: (comment: Comment) => boolean;
  updateCommentCb?: (tertiaryFormId: string, comment: CommentForm) => (comment: Comment) => Comment;
  deleteCommentCb?: (tertiaryFormId: string, fieldName: ApiKeyFields) => (comment: Comment) => boolean;
}

export const useTertiaryListFormState = (): ((props: WorkListFormStateCbProps) => ReturnReleaseFormState) => {
  const getTertiaryFormState = useTertiaryFormState();
  const {
    userStore: { isAdmin },
    revisedFormStore: {
      comments: { tertiaryComments },
      release,
    },
  } = useStores();

  return ({
    secondaryFormName,
    secondaryFormId,
    tertiaryFormName,
    tertiaryFormId,
    filterCommentsCb,
    updateCommentCb,
    deleteCommentCb,
  }) => {
    const formState = getTertiaryFormState({
      secondaryFormName,
      secondaryFormId,
      tertiaryFormName,
      tertiaryFormId,
      updateCommentCb,
      deleteCommentCb,
    });

    const getFormComments = tertiaryComments.filter(filterCommentsCb);
    const getCommentsKeys = getFormComments.map(({ fieldName }) => convertSnakeToCamel(fieldName) as ApiKeyFields);

    if (isAdmin) {
      switch (release?.status) {
        case ReleaseStatus.InRevision:
          return {
            ...formState,
            edit: {
              ...formState.edit,
              excludeFields: [
                ...(formState.edit.excludeFields ?? []),
                ApiKeyFields.isFeatured,
                ApiKeyFields.appleUrl,
                ApiKeyFields.spotifyUrl,
              ],
            },
            comments: {
              ...formState.comments,
              formComments: getFormComments,
            },
          };
        case ReleaseStatus.ChangesNeeded:
          return {
            ...formState,
            readOnly: {
              ...formState.readOnly,
              fields: true,
            },
            comments: {
              ...formState.comments,
              fields: getCommentsKeys,
              formComments: getFormComments,
            },
          };
      }
    }

    switch (release?.status) {
      case ReleaseStatus.ChangesNeeded:
        return {
          ...formState,
          readOnly: {
            ...formState.readOnly,
            fields: false,
          },
          comments: {
            ...formState.comments,
            fields: getCommentsKeys,
            formComments: getFormComments,
          },
        };
    }

    return formState;
  };
};
