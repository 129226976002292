import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";
import { GridRowParams } from "@mui/x-data-grid";

import { RadioStyled } from "components";
import { RowModifyActions } from "components/ReleaseForm";
import { isTrackComplete } from "components/ReleaseForm/helpers";
import type { ReleaseColumnsProps } from "components/ReleaseForm/types";
import { ApiKeyFields, TracksForm } from "types";

interface TrackColumnsProps<T> extends ReleaseColumnsProps<T> {
  onFocusTrackClick: (record: T) => void;
  isAdmin?: boolean;
}

export const getColumns = ({
  onEditClick,
  onDeleteClick,
  onFocusTrackClick,
  getRowComments,
  isReadOnly,
  isAdmin,
}: TrackColumnsProps<TracksForm>): GridColDef[] => [
  {
    field: ApiKeyFields.title,
    headerName: "Track Title",
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.status,
    headerName: "Track Level Status",
    sortable: false,
    flex: 1,
    minWidth: 150,
    renderCell: ({ row }) => {
      const isComplete = isTrackComplete(row);
      return (
        <Typography variant="body1" color={isComplete ? "text.success" : "text.warning"}>
          {isComplete ? "Completed" : "Not Completed"}
        </Typography>
      );
    },
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.isFocus,
    headerName: "Focus Track",
    sortable: false,
    type: "actions",
    align: "center",
    headerAlign: "center",
    flex: 1,
    maxWidth: 110,
    getActions: ({ row }) => {
      return [
        <RadioStyled
          key={row.id}
          checked={row.isFocus}
          onClick={() => onFocusTrackClick(row)}
          disabled={isReadOnly || isAdmin}
        />,
      ];
    },
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.isActive,
    sortable: false,
    type: "actions",
    disableColumnMenu: true,
    getActions: ({ row }: GridRowParams<TracksForm>) => {
      return [
        <RowModifyActions
          key={row.id}
          record={row}
          onDeleteClick={onDeleteClick}
          onEditClick={() => onEditClick(row)}
          getRowComments={getRowComments}
          isReadOnly={isReadOnly}
        />,
      ];
    },
  },
];
