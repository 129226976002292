import * as zod from "zod";

import { MAX_LENGTH_INPUT, MIN_LEN_ERR_TEXT, REQUIRED_ISWC } from "constants/validation";
import { ApiKeyFields } from "types";

export const schema = zod.object({
  [ApiKeyFields.title]: zod
    .string({ invalid_type_error: MIN_LEN_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.agency]: zod
    .string({ invalid_type_error: MIN_LEN_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.iswc]: zod // T-345246800-1
    .string({ invalid_type_error: MIN_LEN_ERR_TEXT })
    .trim()
    .max(MAX_LENGTH_INPUT)
    .optional()
    .default("")
    .refine((value) => value === "" || /^T-\d{9}-\d$/.test(value), {
      message: REQUIRED_ISWC,
    }),
});
