import { languages } from "countries-list";

import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.marketing_info]: "Marketing Copy (Optional)",
};

export const HELPER_TEXT = {
  [ApiKeyFields.marketing_info]:
    "This is used to pitch your release to playlist editors. This should include your hometown, a brief bio, what you want to listeners to take away from the song / why you wrote the song, and any marketing plans.",
} as Record<ApiKeyFields, string>;

export const TRACKS_MAX_LIMIT: number = 20;

export const TRACK_LANGUAGES_OPTIONS = Object.entries(languages)
  .filter(([lng]) => ["AR", "DE", "EN", "ES", "FR", "HE", "HI", "IT", "KO", "LA", "PT"].includes(lng.toUpperCase()))
  .map(([value, { name }]) => ({
    label: name,
    value: value.toUpperCase(),
  }));

export const TRACKS_SIDEBAR_FIELDS = [{ field: ApiKeyFields.marketing_info, required: false }] as SubRouteFieldProps[];
