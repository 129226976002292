import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFormProvider } from "context";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { FormBlockGridGap, Modal, SelectField, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { useTertiaryListFormState } from "components/ReleaseForm/Hooks";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { RELEASE_ROLES_OPTIONS } from "constants/release";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  ContributorsForm,
  PrimaryFormNames,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
} from "types";

import { defaultValues, FORM_LABELS } from "./constants";
import { prepareRequest } from "./helpers";
import { AddEditContributorProps } from "./types";
import { schema } from "./validation";

const AddEditContributor: FC<AddEditContributorProps> = ({ closeModal, track, record, successCallback }) => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const formState = useTertiaryListFormState()({
    secondaryFormName: SecondaryFormNames.ReleaseTracks,
    secondaryFormId: track.id as string,
    tertiaryFormName: TertiaryFormNames.ReleaseContributors,
    tertiaryFormId: record?.id || "",
    filterCommentsCb: ({ releaseContributorId }) => releaseContributorId === record?.id,
    updateCommentCb: (tertiaryFormId, c) => {
      return (comment) =>
        comment.releaseContributorId === tertiaryFormId && comment.fieldName === c.fieldName ? { ...comment, ...c } : comment;
    },
    deleteCommentCb: (tertiaryFormId, fieldName) => {
      return (comment) => !(comment.releaseContributorId === tertiaryFormId && comment.fieldName === fieldName);
    },
  });

  const form = useForm<ContributorsForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty, dirtyFields },
  } = form;

  useEffect(() => {
    record && reset(record);
  }, [record]);

  const onSubmit = handleSubmit(async (formData) => {
    if (!track.id || !track.releaseId) return;
    try {
      setOverlayLoading(true);
      const requestParams: TertiarySecondaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseContributors,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: track.id,
      };
      if (record) {
        const patchRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: record.id! };
        const requestData = prepareRequest(formData, dirtyFields);
        await revisedFormService.patchTertiaryForm(patchRequestParams, requestData);
        const { revisedForm } = await revisedFormService.getTertiaryFormId(patchRequestParams);
        successCallback?.(revisedForm);
      } else {
        const {
          revisedForm: { id },
        } = await revisedFormService.postTertiaryForm(requestParams, formData);
        const getRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: id! };
        const { revisedForm } = await revisedFormService.getTertiaryFormId(getRequestParams);
        successCallback?.(revisedForm);
      }
      closeModal();
    } finally {
      resetLoading();
    }
  });

  const modalContent = (
    <FormProvider {...form}>
      <Modal.ModalTitle title={`${record ? "Edit" : "Add"} the Contributor`} closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem fieldName={ApiKeyFields.artistName} label={FORM_LABELS[ApiKeyFields.artistName]}>
            {(field) => <TextField {...field} control={control} />}
          </FormGridFieldItem>
          <FormGridFieldItem fieldName={ApiKeyFields.role} label={FORM_LABELS[ApiKeyFields.role]}>
            {(field) => <SelectField {...field} control={control} options={RELEASE_ROLES_OPTIONS} />}
          </FormGridFieldItem>
        </Grid>
      </Modal.ModalContent>
      {!formState.isReadOnly || formState.isEditable ? (
        <Modal.ModalActions>
          <Button size="small" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="small" variant="contained" disabled={!isValid || !isDirty} onClick={onSubmit}>
            {record ? "Edit" : "Add"}
          </Button>
        </Modal.ModalActions>
      ) : null}
    </FormProvider>
  );

  if (record) {
    return <CustomFormProvider {...formState}>{modalContent}</CustomFormProvider>;
  }

  return modalContent;
};

export default observer(AddEditContributor);
