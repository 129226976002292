import { ApiKeyFields } from "types";

import { TRACK_LANGUAGES_OPTIONS } from "../constants";

export interface XlsxDataObject {
  [key: string]: ApiKeyFields;
}

const keyMap: { [key: string]: ApiKeyFields } = {
  "Track Title": ApiKeyFields.title,
  Language: ApiKeyFields.language,
  Lyrics: ApiKeyFields.lyrics,
};

function getLanguageValue(label: string) {
  const option = TRACK_LANGUAGES_OPTIONS.find((opt) => opt.label === label);
  return option ? option.value : "";
}

export function transformXlsxKeys(obj: XlsxDataObject) {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = keyMap[key] || key;
    if (newKey === ApiKeyFields.language) {
      acc[newKey] = getLanguageValue(obj[key]) as ApiKeyFields;
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {} as XlsxDataObject);
}
