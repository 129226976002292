import React, { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { CheckboxProps } from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import { CheckboxStyled } from "components/FormControls";

type CheckboxFieldType<FieldType extends FieldValues> = CheckboxProps & {
  fieldName: string;
  control: Control<FieldType>;
  label?: string | ReactNode;
  readOnly?: boolean;
};

const CheckboxField = <FieldType extends FieldValues>({ fieldName, control, label, ...props }: CheckboxFieldType<FieldType>) => {
  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.checked ? true : null);
        };

        return (
          <FormControl fullWidth>
            <FormControlLabel
              control={<CheckboxStyled {...fieldProps} {...props} onChange={handleChange} checked={value === true} />}
              disabled={props.readOnly}
              label={label}
            />
            {error?.message?.trim() ? (
              <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
                {error?.message}
              </Typography>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default CheckboxField;
