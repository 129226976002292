import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import TracksTemplate from "assets/docs/tracks_template.xlsx";
import { DropZoneField, FormHelperTextContainerStyled, HintLinkButtonStyled, Modal } from "components";
import BulkInviteResultModal, { BulkInviteResult } from "components/BulkInviteResultModal";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { ApiKeyFields, PrimaryFormNames, SecondaryFormNames } from "types";
import { onDownloadFile, processWorkbookData, readFileAsArrayBuffer } from "utils";

import { defaultValues } from "./constants";
import { transformXlsxKeys, XlsxDataObject } from "./helpers";
import { HelperTextContainerStyled } from "./styled";

const AddBulkTracks: FC<BaseModalProps> = ({ closeModal }) => {
  const {
    uiStore: { resetLoading, setOverlayLoading, setLoadingText, openModal },
  } = useStores();
  const form = useForm({
    defaultValues,
  });
  const { control, watch, handleSubmit } = form;
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const processTracksData = async (tracksData: XlsxDataObject[]): Promise<BulkInviteResult> => {
    let successCount = 0;
    const failureKeyArray = [];

    for (const data of tracksData) {
      try {
        await revisedFormService.postSecondaryForm(
          {
            userId,
            primaryFormName: PrimaryFormNames.Releases,
            secondaryFormName: SecondaryFormNames.ReleaseTracks,
            primaryFormId: releaseId!,
          },
          data,
        );
        successCount++;
        setLoadingText(`Success: ${successCount}, Failures: ${failureKeyArray.length}`);
      } catch (error) {
        failureKeyArray.push(data[ApiKeyFields.title]);
        setLoadingText(`Success: ${successCount}, Failures: ${failureKeyArray.length}`);
      }
    }

    return { successCount, failureKeyArray };
  };

  const onSubmit = handleSubmit(async ({ template }) => {
    if (!template || !releaseId) return;

    let result: BulkInviteResult = { successCount: 0, failureKeyArray: [] };

    try {
      closeModal();
      setOverlayLoading(true, "Uploading the file...");

      const data = await readFileAsArrayBuffer(template);
      const jsonData = processWorkbookData(data);
      const trackData = jsonData.map((v) => transformXlsxKeys(v as XlsxDataObject));

      result = await processTracksData(trackData);

      openModal({
        component: (modalProps) => (
          <BulkInviteResultModal {...modalProps} {...result} title="Bulk of Tracks result">
            {({ successCount, failureKeyArray, haveErrors }) => (
              <>
                <Typography variant="body1">
                  <b>{successCount}</b> track(s) have been successfully created.
                </Typography>
                {haveErrors ? (
                  <Typography variant="body1" whiteSpace="pre-line">
                    The system was unable to upload the following track(s): <b>{failureKeyArray.join(",\n")}</b>
                  </Typography>
                ) : null}
              </>
            )}
          </BulkInviteResultModal>
        ),
        PaperProps: { sx: { width: result.failureKeyArray.length ? "648px" : "448px" } },
        onClose: () => {
          window.location.reload();
        },
      });
    } finally {
      resetLoading();
    }
  });

  const handleDownloadTemplate = () => onDownloadFile({ title: "trackTemplate", file: TracksTemplate });

  const watchTemplate = watch(ApiKeyFields.template);

  return (
    <>
      <Modal.ModalTitle title="Add the Bulk of Tracks" closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <FormProvider {...form}>
          <FormGridFieldItem
            fieldName={ApiKeyFields.template}
            label="Select File"
            helperText={
              <FormHelperTextContainerStyled>
                <Typography variant="body1" color="text.label">
                  You can upload tracks metadata in an Excel spreadsheet format.
                </Typography>
                <Typography variant="body1" color="text.label">
                  Please download and use file:
                </Typography>
                <HelperTextContainerStyled>
                  <HintLinkButtonStyled variant="link" size="small" onClick={handleDownloadTemplate}>
                    the template (.XSLX)
                  </HintLinkButtonStyled>
                </HelperTextContainerStyled>
              </FormHelperTextContainerStyled>
            }
          >
            {(field) => (
              <DropZoneField
                {...field}
                control={control}
                maxSizeLimit={[100, "MB"]}
                dropFileText="Drop your spreadsheet here"
                options={{
                  multiple: false,
                  accept: {
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                  },
                }}
              />
            )}
          </FormGridFieldItem>
        </FormProvider>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" onClick={onSubmit} disabled={!watchTemplate}>
          Add
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(AddBulkTracks);
