import React, { FC } from "react";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import ElectronicPayment from "assets/docs/ElectronicPayment.pdf";
import { FormHelperTextContainerStyled, HintLinkBlock, HintLinkButtonStyled } from "components";
import { onDownloadFile } from "utils";

import { wForms } from "./constants";

const FormsListStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface HelperTextProps {
  hideDownloadBlock?: boolean;
}

export const TaxFormHelperText: FC<HelperTextProps> = ({ hideDownloadBlock }) => {
  return (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label" whiteSpace="pre-line">
        This is the tax form for the Legal Name/Entity listed above. Please use one of the templates provided below:
      </Typography>
      <Typography variant="body1" color="text.label" whiteSpace="pre-line" component="ul">
        <li>W9 – US resident;</li>
        <li>W8 – Individual resident outside US;</li>
        <li>W8E – Entity outside US.</li>
      </Typography>
      <Typography variant="body1" color="text.label" whiteSpace="pre-line">
        You must provide a tax form in order to receive royalty payments.
      </Typography>
      {!hideDownloadBlock ? (
        <HintLinkBlock>
          <FormsListStyled>
            {wForms.map(({ title, file }) => (
              <React.Fragment key={title}>
                <HintLinkButtonStyled onClick={() => onDownloadFile({ title, file })} variant="link" size="small">
                  {title}
                </HintLinkButtonStyled>
                {title !== "W8E" ? "/" : null}
              </React.Fragment>
            ))}
          </FormsListStyled>
        </HintLinkBlock>
      ) : null}
    </FormHelperTextContainerStyled>
  );
};

export const ElectronicPaymentFormHelperText: FC<HelperTextProps> = ({ hideDownloadBlock }) => {
  return (
    <FormHelperTextContainerStyled>
      <Typography variant="body1" color="text.label" whiteSpace="pre-line">
        Please upload your direct deposit form here to ensure your royalties are paid out! Remember, both this form and your tax
        form above are required to process your payment.
      </Typography>
      {!hideDownloadBlock ? (
        <>
          <Typography variant="body1" color="text.label" whiteSpace="pre-line">
            Please download the template below for use.
          </Typography>
          <HintLinkBlock>
            <HintLinkButtonStyled
              onClick={() => onDownloadFile({ title: "Electronic Payment Form", file: ElectronicPayment })}
              variant="link"
              size="small"
            >
              Electronic Payment Form
            </HintLinkButtonStyled>
          </HintLinkBlock>
        </>
      ) : null}
    </FormHelperTextContainerStyled>
  );
};
