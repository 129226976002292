import { FC, useMemo } from "react";
import { observer } from "mobx-react";

import { DndTableGrid, ReleaseTableListBlock } from "components";
import { TableNoDataMessage, usePrimaryFormState } from "components/ReleaseForm";

import { getColumns } from "./columns";
import { RELATED_WORKS_MAX_LIMIT } from "./constants";
import { RelatedWorksProps } from "./types";
import { useRelatedWorks } from "./useRelatedWorks";

const TableNoData = () => <TableNoDataMessage text="There are no related work(s) selected." />;

const RelatedWorksBlock: FC<RelatedWorksProps> = ({ track }) => {
  const { fields, relatedWorksLoading, handleSelectWork, handleDeleteClick, handleChangeRows } = useRelatedWorks(track);
  const { isReadOnly } = usePrimaryFormState();

  const columns = useMemo(() => getColumns({ onDeleteClick: handleDeleteClick, isReadOnly }), [handleDeleteClick]);

  const itemsCount = Boolean(fields.length) ? fields.length : null;

  return (
    <ReleaseTableListBlock
      title="Related Work(s)"
      itemsCount={itemsCount}
      maxLimit={RELATED_WORKS_MAX_LIMIT}
      onAddClick={handleSelectWork}
      addBtnText="Select Work"
      isLoading={relatedWorksLoading}
      hideActionBtn={isReadOnly}
    >
      <DndTableGrid
        rows={fields}
        columns={columns}
        onOrderChange={handleChangeRows}
        slots={{ noRowsOverlay: TableNoData }}
        isDragEnabled={!isReadOnly}
      />
    </ReleaseTableListBlock>
  );
};

export default observer(RelatedWorksBlock);
