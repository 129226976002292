import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { NumberInputField, SelectField, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { TERRITORY_LABEL_VALUE_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT } from "./contstants";
import { AddEditFormProps } from "./types";

const AddEditForm: FC<AddEditFormProps> = ({ hideAdministratorField, labelsOverride }) => {
  const { control } = useFormContext();

  const FIELD_LABELS = { ...FORM_LABELS, ...labelsOverride };

  return (
    <>
      <FormGridFieldItem fieldName={ApiKeyFields.name} label={FIELD_LABELS[ApiKeyFields.name]}>
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      {!hideAdministratorField ? (
        <FormGridFieldItem fieldName={ApiKeyFields.rightsAdministrator} label={FIELD_LABELS[ApiKeyFields.rightsAdministrator]}>
          {(field) => <TextField {...field} control={control} />}
        </FormGridFieldItem>
      ) : null}
      <FormGridFieldItem fieldName={ApiKeyFields.affiliation} label={FIELD_LABELS[ApiKeyFields.affiliation]}>
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      <FormGridFieldItem fieldName={ApiKeyFields.country} label={FIELD_LABELS[ApiKeyFields.country]}>
        {(field) => <SelectField {...field} control={control} options={TERRITORY_LABEL_VALUE_OPTIONS} />}
      </FormGridFieldItem>
      <FormGridFieldItem
        fieldName={ApiKeyFields.split}
        label={FIELD_LABELS[ApiKeyFields.split]}
        helperText={HELPER_TEXT[ApiKeyFields.split]}
      >
        {(field) => <NumberInputField {...field} control={control} addonAfter={<span>%</span>} />}
      </FormGridFieldItem>
    </>
  );
};

export default AddEditForm;
