import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { ReleaseTableListBlock } from "components";
import DndMuiTable from "components/DndMuiTable";
import { FooterSplitTotal, usePrimaryFormState } from "components/ReleaseForm";
import { Songwriters } from "types";

import { getColumns } from "./columns";
import { SONGWRITERS_MAX_LIMIT } from "./constants";
import { SongwritersProps } from "./types";
import { useSongwriters } from "./useSongwriters";

const SongwritersBlock: FC<SongwritersProps> = ({ work }) => {
  const {
    revisedFormStore: {
      comments: { tertiaryComments },
    },
  } = useStores();
  const {
    fields,
    // songwritersLoading,
    handleChangeRows,
    handleAddSongwriter,
    handleCopySongwriter,
    handleEditSongwriter,
    handleDeleteClick,
  } = useSongwriters(work);

  const getRowComments = (songwriter: Songwriters) =>
    tertiaryComments.filter(({ releaseSongwriterId }) => releaseSongwriterId === songwriter.id);

  const { isReadOnly } = usePrimaryFormState();
  const columns = useMemo(
    () => getColumns({ onEditClick: handleEditSongwriter, onDeleteClick: handleDeleteClick, getRowComments, isReadOnly }),
    [handleEditSongwriter, handleDeleteClick],
  );

  const itemsCount = Boolean(fields.length) ? fields.length : null;

  return (
    <ReleaseTableListBlock
      title="Songwriter(s)"
      itemsCount={itemsCount}
      maxLimit={SONGWRITERS_MAX_LIMIT}
      onAddClick={handleAddSongwriter}
      onCopyClick={handleCopySongwriter}
      addBtnText="Add New Songwriter"
      copyBtnText="Copy Songwriter"
      hideActionBtn={isReadOnly}
    >
      <DndMuiTable
        rows={fields}
        columns={columns}
        onOrderChange={handleChangeRows}
        isDragEnabled={!isReadOnly}
        emptyText="There are no songwriter(s) added."
        footer={<FooterSplitTotal fields={fields} columns={columns} isDragEnabled={!isReadOnly} />}
      />
    </ReleaseTableListBlock>
  );
};

export default observer(SongwritersBlock);
