import { FC, useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFormProvider } from "context";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { TrackIcon } from "assets/icons/24px";
import { FormBlock, Modal } from "components";
import { usePrimaryFormState } from "components/ReleaseForm";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { ApiKeyFields, PrimaryFormNames, SecondaryFormId, SecondaryFormNames, TracksForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import ArtistsBlock from "./ListBlocks/ArtistsBlock";
import ContributorsBlock from "./ListBlocks/ContributorsBlock";
import RelatedWorksBlock from "./ListBlocks/RelatedWorksBlock";
import { defaultValues } from "./TrackForm/constants";
import { schema } from "./TrackForm/validation";
import HelpAccordionText from "./HelpAccordionText";
import { prepareFormData, prepareFormRequest } from "./helpers";
import TrackForm from "./TrackForm";
import { TrackProps } from "./types";
import { useTrackFormState } from "./useTrackFormState";

const Track: FC<TrackProps> = ({ track, closeModal, successCb }) => {
  const {
    revisedFormStore: {
      tracks: { retrieveTrackLists, resetTrackLists },
      comments: { resetTertiaryComments },
    },
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const { isReadOnly, isEditable, isAdmin } = usePrimaryFormState();
  const getSecondaryFormState = useTrackFormState();
  const formState = getSecondaryFormState(track.id as string);

  useEffect(() => {
    return () => {
      resetTertiaryComments();
    };
  }, []);

  const form = useForm<TracksForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields, isValid },
  } = form;

  const watchAudioBlob = useWatch({
    control,
    name: ApiKeyFields.audioBlobPath,
  });

  useEffect(() => {
    if (track[ApiKeyFields.audioBlobPath] !== watchAudioBlob && watchAudioBlob) {
      successCb?.(replaceEmptyAndUndefinedWithNull({ ...track, [ApiKeyFields.audioBlobPath]: watchAudioBlob }));
    }
  }, [track, watchAudioBlob]);

  useEffect(() => {
    track.id && retrieveTrackLists(userId, releaseId!, track.id!);
    return () => {
      resetTrackLists();
    };
  }, [track?.id]);

  useEffect(() => {
    if (track?.id) {
      reset(prepareFormData(track));
    }
  }, [reset, track?.id]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      setOverlayLoading(true);
      const requestParams: SecondaryFormId = {
        userId,
        primaryFormId: releaseId!,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormId: track.id!,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
      };
      const requestData = prepareFormRequest(formData, dirtyFields, isAdmin);
      await revisedFormService.patchSecondaryForm(requestParams, requestData);
      reset(formData, { keepDirty: false });
      successCb?.(replaceEmptyAndUndefinedWithNull({ ...track, ...formData }));
      enqueueSnackbar("The track has been successfully saved.");
    } finally {
      resetLoading();
    }
  });

  return (
    <CustomFormProvider {...formState}>
      <FormProvider {...form}>
        <Modal.ModalFullScreenTitle title="Add the Track" closeModal={closeModal}>
          {!isReadOnly || isEditable ? (
            <Button variant="contained" size="medium" disabled={!isValid || !isDirty} onClick={onSubmit}>
              Save
            </Button>
          ) : null}
        </Modal.ModalFullScreenTitle>
        <Modal.ModalFullScreenContent>
          <Grid container flex={1} justifyContent="center" alignSelf="flex-start" paddingBottom="56px">
            <Grid item xs={10} md={8} display="flex" flexDirection="column" gap="16px">
              <FormBlock title="Track Information" icon={<TrackIcon />}>
                <TrackForm track={track} />
              </FormBlock>
              <ArtistsBlock track={track} />
              <ContributorsBlock track={track} />
              <RelatedWorksBlock track={track} />
            </Grid>
          </Grid>
        </Modal.ModalFullScreenContent>
      </FormProvider>
      {!isAdmin ? <HelpAccordionText /> : null}
    </CustomFormProvider>
  );
};

export default observer(Track);
