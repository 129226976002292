import React from "react";
import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { DialogProps } from "@mui/material/Dialog";
import { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";

// move types it outside!
interface ModalComponentProps {
  modalId: string;
  closeModal: () => void;
}

type ModalComponent = React.FC<ModalComponentProps>;

export interface ModalProps extends Omit<DialogProps, "open"> {
  component: ModalComponent;
  onClose?: () => void;
}

interface DrawerProps extends MuiDrawerProps {
  component: typeof React.Component | React.ElementType;
  onClose?: () => void;
}

export interface ModalInstance {
  id: string;
  modalProps: ModalProps;
}

export class UiStore {
  @observable isLoading: boolean = false;
  @observable isOverlayLoading: boolean = false;
  @observable loadingText: string | null = null;

  @observable modals: ModalInstance[] = [];

  @observable isDrawerOpen: boolean = false;
  @observable drawerProps: DrawerProps | null = null;

  @observable isFiltersOpen: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @action
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setLoadingText = (loadingText: string) => {
    this.loadingText = loadingText;
  };

  @action.bound
  setOverlayLoading = (isOverlayLoading: boolean, loadingText?: string) => {
    this.isOverlayLoading = isOverlayLoading;
    this.loadingText = loadingText || null;
  };

  @action.bound
  resetLoading = () => {
    this.isLoading = false;
    this.isOverlayLoading = false;
    this.loadingText = null;
  };

  openModal = (modalProps: ModalProps) => {
    const id = crypto.randomUUID();
    this.modals = [...this.modals, { id, modalProps }];
  };

  @action
  closeModal = (id: string) => {
    const modal = this.modals.find((modal) => modal.id === id);
    if (modal?.modalProps.onClose) {
      modal.modalProps.onClose();
    }
    this.modals = this.modals.filter((modal) => modal.id !== id);
  };

  openDrawer = (drawerProps: DrawerProps) => {
    this.isDrawerOpen = true;
    this.drawerProps = drawerProps;
  };

  @action
  onUploadingProgress = (loadedBytes: number, fileSize: number) => {
    const progress = Math.round((loadedBytes / fileSize) * 100);
    this.loadingText = `${progress.toString()} %`;
  };

  @action
  toggleFilterOpen = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };

  @action
  resetFilterOpen = () => {
    this.isFiltersOpen = false;
  };

  @action
  closeDrawer = () => {
    this.isDrawerOpen = false;
    this.drawerProps = null;
  };
}
