import { ProgressBarConfigProps, SubRouteConfigProps } from "components/FormProgressBar";
import { releaseDateRouteConfig, tracksSubRouteConfig, worksSubRouteConfig } from "components/ReleaseForm/helpers";
import { RELEASE_DATE_SIDEBAR_FIELDS } from "components/ReleaseForm/ReleaseDate/constants";
import { GENRE_TERRITORY_SIDEBAR_FIELDS } from "components/ReleaseForm/ReleaseInformation/GenreTerritory/constants";
import { TITLE_COVER_ART_SIDEBAR_FIELDS } from "components/ReleaseForm/ReleaseInformation/TitleCoverArt/constants";
import { TRACKS_SIDEBAR_FIELDS } from "components/ReleaseForm/TrackManager/Tracks/constants";
import { RevisedListCounts } from "components/ReleaseForm/types";
import { ROUTES } from "constants/routes";

export const baseSubRouteConfig = (
  baseRoute: string,
  worksSubRouteConfig?: (props: SubRouteConfigProps) => string,
  tracksSubRouteConfig?: (props: SubRouteConfigProps) => string,
  releaseDateRouteConfig?: (props: SubRouteConfigProps) => string,
): ProgressBarConfigProps[] => {
  const createRoute = (url: string) => `${baseRoute}/${url}`;

  return [
    {
      title: "Release Information",
      subRoutes: [
        {
          route: baseRoute,
          fields: TITLE_COVER_ART_SIDEBAR_FIELDS,
          stepTitle: "Release Title, Cover Art",
        },
        {
          route: createRoute(ROUTES.GENRE_ROUTE),
          fields: GENRE_TERRITORY_SIDEBAR_FIELDS,
          stepTitle: "Genre, Territory",
        },
      ],
    },
    {
      title: "Track(s) Manager",
      subRoutes: [
        {
          route: createRoute(ROUTES.WORKS_ROUTE),
          fields: [],
          stepTitle: "Add Work(s)",
          subRouteConfig: worksSubRouteConfig,
        },
        {
          route: createRoute(ROUTES.TRACKS_ROUTE),
          fields: TRACKS_SIDEBAR_FIELDS,
          stepTitle: "Add Track(s)",
          subRouteConfig: tracksSubRouteConfig,
        },
      ],
    },
    {
      title: "Release Date",
      subRoutes: [
        {
          route: createRoute(ROUTES.RELEASE_DATE_ROUTE),
          fields: RELEASE_DATE_SIDEBAR_FIELDS,
          stepTitle: "Schedule the Date",
          subRouteConfig: releaseDateRouteConfig,
        },
      ],
    },
  ];
};

export const progressBarConfig = (baseRoute: string, comments: RevisedListCounts): ProgressBarConfigProps[] => {
  return baseSubRouteConfig(
    baseRoute,
    (props) => worksSubRouteConfig({ ...props, comments }),
    (props) => tracksSubRouteConfig({ ...props, comments }, true),
    (props) => releaseDateRouteConfig({ ...props, comments }, true),
  );
};
