import dayjs from "dayjs";

import { ApiKeyFields } from "types";

import { AdminReleaseFilters, ProcessedFilters } from "./types";

export const processFilters = (filters?: AdminReleaseFilters) => {
  if (!filters) return {};

  const processedFilters: ProcessedFilters = {};

  if (filters[ApiKeyFields.title]) {
    processedFilters[ApiKeyFields.title] = filters[ApiKeyFields.title];
  }

  if (filters[ApiKeyFields.release_track_title]) {
    processedFilters[ApiKeyFields.release_track_title] = filters[ApiKeyFields.release_track_title];
  }

  if (filters[ApiKeyFields.statuses]?.length) {
    processedFilters[ApiKeyFields.statuses] = filters[ApiKeyFields.statuses].join(",");
  }

  if (filters[ApiKeyFields.op_statuses]?.length) {
    processedFilters[ApiKeyFields.op_statuses] = filters[ApiKeyFields.op_statuses].join(",");
  }

  if (filters[ApiKeyFields.op_upcs]) {
    processedFilters[ApiKeyFields.op_upcs] = filters[ApiKeyFields.op_upcs].split("\n").join(",");
  }

  const schedules: DateRangeValue | null = filters[ApiKeyFields.schedules_at];
  if (schedules) {
    const [from, to] = schedules;
    processedFilters[ApiKeyFields.schedules_at_from] = from ? dayjs(from).format("YYYY-MM-DD") : null;
    processedFilters[ApiKeyFields.schedules_at_to] = to ? dayjs(to).format("YYYY-MM-DD") : null;
  }

  return processedFilters;
};
