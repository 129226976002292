import { FC } from "react";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";

import ToastSuccess1x from "assets/images/1x/Illustration_Toast-Success1x.jpg";
import ToastWarning1x from "assets/images/1x/Illustration_Toast-Warning1x.jpg";
import ToastSuccess2x from "assets/images/2x/Illustration_Toast-Success2x.png";
import ToastWarning2x from "assets/images/2x/Illustration_Toast-Warning2x.png";
import { Modal, ProgressiveImage } from "components";

import { ContentContainerStyled, TypographyContainerStyled } from "./styled";
import { BulkInviteResultModalProps } from "./types";

const BulkInviteResultModal: FC<BulkInviteResultModalProps> = ({
  title,
  successCount,
  failureKeyArray,
  closeModal,
  children,
}) => {
  const haveErrors = !!failureKeyArray.length;

  return (
    <>
      <Modal.ModalTitle title={title ?? "Invite results"} closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <ContentContainerStyled>
          <ProgressiveImage
            placeholder={haveErrors ? ToastWarning1x : ToastSuccess1x}
            src={haveErrors ? ToastWarning2x : ToastSuccess2x}
          >
            {(src) => <img src={src} alt="Toast Message" />}
          </ProgressiveImage>
          <TypographyContainerStyled>{children({ successCount, failureKeyArray, haveErrors })}</TypographyContainerStyled>
        </ContentContainerStyled>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Close
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(BulkInviteResultModal);
