import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export const ContainerWrapperStyled = styled.div`
  height: fit-content;
`;

export const ContainerStyled = styled(Container)`
  && {
    padding-left: 0;
    padding-right: 0;
  }

  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

export const ContentContainerStyled = styled(Container, {
  shouldForwardProp: (prop) => !["icon", "height", "padding"].includes(prop),
})<{ icon?: boolean; height?: string; padding?: string }>`
  ${({ padding }) => (padding ? `padding: ${padding}` : `padding: 18px 20px;`)}

  ${({ height }) => height && `height: ${height}`}

  ${({ icon }) => icon && `display: flex; align-items: center; svg { margin-right: 8px; }`}
`;

type Props = {
  children: ReactNode;
  ContainerHeader?: ReactNode;
  title?: string;
  icon?: ReactNode;
  contentContainerHeight?: string;
  contentContainerPadding?: string;
  noPaddings?: boolean;
};

export const FormBlockGridGap = "24px 0";

const FormBlock: FC<Props> = ({
  children,
  title,
  icon: Icon,
  contentContainerHeight,
  contentContainerPadding,
  ContainerHeader,
  noPaddings,
}) => {
  return (
    <ContainerWrapperStyled>
      {ContainerHeader ? ContainerHeader : null}
      <ContainerStyled>
        {title ? (
          <>
            <ContentContainerStyled icon={Boolean(Icon)}>
              {Icon ? Icon : null}
              <Typography variant="h2">{title}</Typography>
            </ContentContainerStyled>
            <Divider />
          </>
        ) : null}
        <ContentContainerStyled height={contentContainerHeight} padding={noPaddings ? "0px !important" : contentContainerPadding}>
          {children}
        </ContentContainerStyled>
      </ContainerStyled>
    </ContainerWrapperStyled>
  );
};

export default FormBlock;
