import * as zod from "zod";

import { MIN_LEN_ERR_MSG, SELECT_ERR_MSG } from "constants/validation";
import { ApiKeyFields } from "types";

export const genreTerritorySchema = zod.object({
  [ApiKeyFields.genre]: zod.string().min(1, MIN_LEN_ERR_MSG),
  [ApiKeyFields.sub_genre]: zod.string().nullable().optional(),
  [ApiKeyFields.territory]: zod.array(zod.string()).min(1, SELECT_ERR_MSG),
});
