import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Modal from "components/Modal";
import { RESOURCES_KEYS } from "i18n";
import { userService } from "services";
import { UserArtist } from "types";
import { getUserName } from "utils/transformObjects";

interface BlockUserModalProps extends BaseModalProps {
  user: UserArtist;
  changeUserIsActive: (id: string, isActive: boolean) => void;
}

const BlockUserModal: FC<BlockUserModalProps> = ({ user, changeUserIsActive, closeModal }) => {
  const { t } = useTranslation([RESOURCES_KEYS.APPLICATIONS, RESOURCES_KEYS.COMMON]);
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const name = useMemo(() => getUserName(user), [user]);

  const handleIsUserActive = async () => {
    setOverlayLoading(true);
    try {
      closeModal();
      await userService.patchUserById(user.id, { user: { isActive: !user.isActive } });
      await changeUserIsActive(user.id, !user.isActive);
      enqueueSnackbar(
        user.isActive
          ? t("user.decline.success", {
              name,
            })
          : t("user.unlock.success", {
              name,
            }),
      );
    } finally {
      resetLoading();
    }
  };

  return (
    <>
      <Modal.ModalTitle title={user.isActive ? t("user.decline.title") : t("user.unlock.title")} closeModal={closeModal} />
      <Modal.ModalContent>
        <Typography variant="body1" whiteSpace="pre-line">
          <Trans
            t={t}
            i18nKey={user.isActive ? "user.decline.description" : "user.unlock.description"}
            values={{ name }}
            components={{ b: <b /> }}
          />
        </Typography>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          {t("Cancel", { ns: RESOURCES_KEYS.COMMON })}
        </Button>
        <Button size="small" variant="contained" onClick={handleIsUserActive}>
          {user.isActive ? t("Decline", { ns: RESOURCES_KEYS.COMMON }) : t("Unlock", { ns: RESOURCES_KEYS.COMMON })}
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(BlockUserModal);
