import * as zod from "zod";

import { MAX_LENGTH_INPUT, MIN_LEN_ERR_TEXT, MIN_LEN_NUM_ERR_TEXT, SELECT_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

export const schema = zod.object({
  [ApiKeyFields.title]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.versionTitle]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .max(MAX_LENGTH_INPUT)
    .nullable()
    .optional(),
  [ApiKeyFields.previewStartsAt]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .regex(/^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/, "Please check the value. The format should be HH:MM:SS.")
    .nullable()
    .optional()
    .or(zod.literal("")),
  [ApiKeyFields.language]: zod.string({ invalid_type_error: SELECT_ERR_TEXT }).min(1, { message: SELECT_ERR_TEXT }),
  [ApiKeyFields.audioBlobPath]: zod.string({ invalid_type_error: SELECT_ERR_TEXT }).nullable().optional(),
  [ApiKeyFields.lyrics]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .min(1, MIN_LEN_ERR_TEXT)
    .max(5000)
    .nullable()
    .optional(),
  [ApiKeyFields.aiAudio]: zod.boolean().nullable().optional(),
});
