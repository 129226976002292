import Typography from "@mui/material/Typography";

import { HelpAccordion } from "components/FormComponents";

const HelpAccordionText = () => {
  return (
    <HelpAccordion title="Need some help?" position="fixed">
      <Typography variant="body1" flex={1}>
        If you’d like your release to be pitched, please be sure to submit a focus track and a marketing copy.
      </Typography>
      <ul>
        {[
          "Focus Track: This is the track the editorial playlist editors will listen to. If selected for placement, this track will be added to playlists.",
          "Marketing Copy: A small paragraph that includes a brief artist bio, why this release is important or what you’d like listeners to take away from the track/release, artist social media statistics, and any marketing plans.",
        ].map((text) => (
          <li key={text}>
            <Typography variant="body1">{text}</Typography>
          </li>
        ))}
      </ul>
      <Typography variant="body1" flex={1}>
        The order in which you arrange the tracks is the order that will appear on the music streaming platforms. You can change
        the order of the tracks by dragging a row and dropping it in the place of another row.
      </Typography>
    </HelpAccordion>
  );
};

export default HelpAccordionText;
