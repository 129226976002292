import { FC, useContext } from "react";
import { useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { CustomFormContext } from "context";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FormBlock, FormBlockGridGap } from "components/FormComponents";
import { FormGridFieldItem, FormGridItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { TextAreaField } from "components/HookFormControls";

import { ARTIST_CATALOG_FIELDS, FORM_LABELS, MAX_LENGTH_TEXT } from "./constants";
import HelpAccordionText from "./HelpAccordionText";

const HelperTextContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HelperText = () => {
  return (
    <HelperTextContainerStyled>
      {[
        "This includes previously released albums, EPs, or singles.",
        "Please enter the names of the albums/EPs/singles in one column, so each name should be on a separate line.",
        "If you do not have a catalog, you can enter “None”.",
      ].map((text) => (
        <Typography variant="body1" whiteSpace="break-spaces" key={text}>
          {text}
        </Typography>
      ))}
    </HelperTextContainerStyled>
  );
};

interface DistributionAgreementProps extends StepFlowControllerProps {
  isAdmin?: boolean;
}

const DistributionAgreement: FC<DistributionAgreementProps> = (props) => {
  const { control } = useFormContext();
  const { isFieldEdited, isFieldCommented } = useContext(CustomFormContext);

  const getDefaultTextAreaProps = () => ({
    control: control,
    multiline: true,
    maxLength: MAX_LENGTH_TEXT,
    rows: 5,
  });

  return (
    <>
      <StepFlowController {...props}>
        <FormBlock>
          <Grid container gap={FormBlockGridGap}>
            <FormGridItem
              label={"Please list all artist catalog that will be included in this distribution agreement."}
              helperText={<HelperText />}
              inputGrid={{ md: 6 }}
            >
              {ARTIST_CATALOG_FIELDS.map((fieldName) => (
                <FormGridFieldItem
                  fieldName={fieldName}
                  key={fieldName}
                  label=""
                  hideLabelGrid
                  inputGrid={
                    isFieldEdited(fieldName) || isFieldCommented(fieldName)
                      ? { md: isFieldEdited(fieldName) && isFieldCommented(fieldName) ? 9 : 10 }
                      : { md: 12 }
                  }
                >
                  {(field) => (
                    <TextAreaField
                      {...field}
                      label={FORM_LABELS[fieldName]}
                      fieldName={fieldName}
                      {...getDefaultTextAreaProps()}
                    />
                  )}
                </FormGridFieldItem>
              ))}
            </FormGridItem>
          </Grid>
        </FormBlock>
      </StepFlowController>
      {!props.isAdmin ? <HelpAccordionText /> : null}
    </>
  );
};

export default DistributionAgreement;
