import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useStores } from "stores";

import Typography from "@mui/material/Typography";

import { ConfirmModal, DndOrderIndex } from "components";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseTrackWorks,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TracksForm,
} from "types";

import SelectWork from "./SelectWork";

export const useRelatedWorks = (track: TracksForm) => {
  const {
    revisedFormStore: {
      tracks: { relatedWorks, relatedWorksLoading },
    },
    uiStore: { setOverlayLoading, resetLoading, openModal },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const { control, setValue, watch } = useFormContext<{ trackWorks: ReleaseTrackWorks[] }>();
  const { fields } = useFieldArray({
    control,
    name: "trackWorks",
    keyName: "_id",
  });

  useEffect(() => {
    relatedWorks && setValue("trackWorks", relatedWorks, { shouldDirty: false });
  }, [relatedWorks]);

  const handleTrackWorksArrayChange = (data: ReleaseTrackWorks) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [...fields, data];
    setValue("trackWorks", newArray, { shouldDirty: false });
  };

  const handleChangeRows = useCallback(
    async (rows: ReleaseTrackWorks[], { afterId, currentItemId }: DndOrderIndex) => {
      const prevValues = [...watch("trackWorks")];
      try {
        setValue("trackWorks", rows, { shouldDirty: false });
        const requestParams: TertiaryFormId = {
          userId,
          [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
          [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
          [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseTrackWorks,
          [ApiKeyFields.primaryFormId]: releaseId!,
          [ApiKeyFields.secondaryFormId]: track.id!,
          [ApiKeyFields.tertiaryFormId]: currentItemId,
        };
        await revisedFormService.reorderTertiaryForm(requestParams, { afterId });
      } catch {
        setValue("trackWorks", prevValues, { shouldDirty: false });
      }
    },
    [setValue, track],
  );

  const handleDeleteRelatedWork = async (record: ReleaseTrackWorks, closeModal: () => void) => {
    try {
      setOverlayLoading(true);
      const requestParams: TertiaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseTrackWorks,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: track.id!,
        [ApiKeyFields.tertiaryFormId]: record.id as string,
      };
      await revisedFormService.deleteTertiaryFormId(requestParams);
      closeModal();
      const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
      setValue("trackWorks", updatedArray, { shouldDirty: false });
    } finally {
      resetLoading();
    }
  };

  const handleSelectWork = () => {
    openModal({
      component: (modalProps) => <SelectWork {...modalProps} track={track} successCallback={handleTrackWorksArrayChange} />,
      fullWidth: true,
      maxWidth: "sm",
    });
  };

  const handleDeleteClick = (record: ReleaseTrackWorks) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the related work?"
          text={
            <Typography variant="body1">
              Remove <b>{record.releaseWork?.title}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteRelatedWork(record, modalProps.closeModal)}
        />
      ),
    });
  };

  return {
    fields,
    relatedWorksLoading,
    handleTrackWorksArrayChange,
    handleChangeRows,
    handleDeleteRelatedWork,
    handleSelectWork,
    handleDeleteClick,
  };
};
