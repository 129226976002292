import { ApiKeyFields } from "types";

export const FORM_FIELDS = [ApiKeyFields.title, ApiKeyFields.agency, ApiKeyFields.iswc];

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.title]: "Work Title",
  [ApiKeyFields.agency]: "Agency",
  [ApiKeyFields.iswc]: "ISWC (Optional)",
};

export const HELPER_TEXT = {
  [ApiKeyFields.agency]: "Royalty/licensing organization for this published work (e.g., BMI, ASCAP, Harry Fox Agency).",
  [ApiKeyFields.iswc]: "Please enter the ISWC code in the following format e.g. T-034524680-1",
} as Record<ApiKeyFields, string>;

export const defaultValues = {
  [ApiKeyFields.title]: "",
  [ApiKeyFields.agency]: "",
  [ApiKeyFields.iswc]: "",
};
