import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";

import { Modal } from "components";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { PrimaryFormNames, SecondaryFormNames, WorksForm } from "types";

import { useWorks } from "../useWorks";
import WorkForm from "../WorkForm";
import { defaultValues } from "../WorkForm/constants";
import { prepareRequest } from "../WorkForm/helpers";
import { schema } from "../WorkForm/validation";

interface AddWorkProps extends BaseModalProps {
  successCallback: (work: WorksForm) => void;
}

const AddWork: FC<AddWorkProps> = ({ successCallback, closeModal }: AddWorkProps) => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { userId = null, releaseId: primaryFormId } = useParams<ReleaseUrlParams>();
  const { handleOpenWorkClick } = useWorks();

  const form = useForm<WorksForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isValid, dirtyFields },
  } = form;

  const onSubmit = handleSubmit(async (data) => {
    if (!primaryFormId) return;
    try {
      setOverlayLoading(true);
      const requestData = prepareRequest(data, dirtyFields);
      const { revisedForm } = await revisedFormService.postSecondaryForm(
        {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseWorks,
          primaryFormId,
        },
        requestData,
      );
      successCallback(revisedForm);
      closeModal();
      handleOpenWorkClick(revisedForm);
    } finally {
      resetLoading();
    }
  });

  return (
    <FormProvider {...form}>
      <Modal.ModalTitle title="Add the Work" closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <WorkForm />
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" disabled={!isValid} onClick={onSubmit}>
          Add
        </Button>
      </Modal.ModalActions>
    </FormProvider>
  );
};

export default observer(AddWork);
