import { FC } from "react";
import { DropzoneOptions } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useStores } from "stores";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { CheckboxField, DropZoneField, FormBlockGridGap, SelectField, TextAreaField, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { useReleaseService } from "pages/User/Releases/Release/useReleaseService";
import { ApiKeyFields, TracksForm } from "types";
import { FileOptionProps } from "types/revisedForms";

import { FORM_BASIC_FIELDS, FORM_LABELS, HELPER_TEXT, TRACK_LANGUAGES_OPTIONS } from "./constants";
import { validateWavFile } from "./helpers";
import { TrackFormProps } from "./types";

const TrackForm: FC<TrackFormProps> = ({ track }) => {
  const { control } = useFormContext<TracksForm>();
  const { currentReleaseBlobActionLvl2 } = useReleaseService();
  const {
    userStore: { isAdmin },
  } = useStores();

  const getDefaultDropzoneProps = () => ({
    options: {
      multiple: false,
      accept: {
        "audio/wav": [".wav"],
      },
    } as DropzoneOptions,
    fileAction: (_: ApiKeyFields, options: FileOptionProps) =>
      currentReleaseBlobActionLvl2(
        { field_name: ApiKeyFields.audio_blob_path, primaryFormId: track.releaseId!, secondaryFormId: track.id! },
        options,
      ),
    isDownloadAllowed: isAdmin,
    customValidation: validateWavFile,
  });

  return (
    <Grid container gap={FormBlockGridGap}>
      {FORM_BASIC_FIELDS.map((fieldName) => (
        <FormGridFieldItem
          key={fieldName}
          label={FORM_LABELS[fieldName]}
          fieldName={fieldName}
          helperText={HELPER_TEXT[fieldName]}
        >
          {(field) => <TextField {...field} control={control} />}
        </FormGridFieldItem>
      ))}
      <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.language]} fieldName={ApiKeyFields.language}>
        {(field) => <SelectField {...field} control={control} options={TRACK_LANGUAGES_OPTIONS} />}
      </FormGridFieldItem>
      <FormGridFieldItem
        label={FORM_LABELS[ApiKeyFields.audioBlobPath]}
        fieldName={ApiKeyFields.audioBlobPath}
        helperText={HELPER_TEXT[ApiKeyFields.audioBlobPath]}
      >
        {(field) => <DropZoneField {...field} {...getDefaultDropzoneProps()} control={control} formFile={false} />}
      </FormGridFieldItem>
      <FormGridFieldItem
        label={FORM_LABELS[ApiKeyFields.lyrics]}
        fieldName={ApiKeyFields.lyrics}
        helperText={HELPER_TEXT[ApiKeyFields.lyrics]}
      >
        {(field) => <TextAreaField {...field} control={control} rows={5} maxLength={5000} />}
      </FormGridFieldItem>
      <Grid item xs={12} md={12}>
        <Divider orientation="horizontal" />
      </Grid>
      <FormGridFieldItem label={""} fieldName={ApiKeyFields.aiAudio} hideLabelGrid inputGrid={{ md: 11 }}>
        {(field) => <CheckboxField {...field} control={control} label={FORM_LABELS[ApiKeyFields.aiAudio]} />}
      </FormGridFieldItem>
    </Grid>
  );
};

export default TrackForm;
