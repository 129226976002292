import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.name]: "",
  [ApiKeyFields.affiliation]: "",
  [ApiKeyFields.country]: "",
  [ApiKeyFields.split]: null,
};

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.name]: "Songwriter Name",
};
