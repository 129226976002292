import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { CheckboxField, DatePickerField, FormBlock, FormBlockGridGap, TextAreaField, ToggleButtonField } from "components";
import { FormAccordion, StepFlowController } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { EN_DATE_FORMAT, YES_NO } from "constants/options";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT } from "./constants";
import { disabledDate } from "./helpers";
import { ReleaseDateProps } from "./types";

const ReleaseDate: FC<ReleaseDateProps> = (props) => {
  const { control, setValue, watch } = useFormContext();

  const watchScheduleEarlier = useWatch({
    control,
    name: ApiKeyFields.schedule_earlier,
  });

  useEffect(() => {
    if (!watchScheduleEarlier) {
      setValue(ApiKeyFields.additional_options, "", { shouldValidate: true });
    }
  }, [watchScheduleEarlier]);

  const scheduleEarlier = watch(ApiKeyFields.schedule_earlier);

  const isScheduleEarlier = scheduleEarlier === true ? true : void 0;

  return (
    <StepFlowController {...props}>
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem
            label={FORM_LABELS[ApiKeyFields.schedules_at]}
            helperText={HELPER_TEXT[ApiKeyFields.schedules_at]}
            fieldName={ApiKeyFields.schedules_at}
          >
            {(field) => (
              <DatePickerField
                {...field}
                control={control}
                disabledDate={!props.isAdmin ? disabledDate : void 0}
                format={EN_DATE_FORMAT}
                placeholder={EN_DATE_FORMAT}
              />
            )}
          </FormGridFieldItem>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormAccordion title="Additional Options" expanded={isScheduleEarlier}>
              <Grid container gap={FormBlockGridGap}>
                <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.schedule_earlier]} fieldName={ApiKeyFields.schedule_earlier}>
                  {(field) => <ToggleButtonField {...field} control={control} options={YES_NO} />}
                </FormGridFieldItem>
                <FormGridFieldItem
                  label={""}
                  fieldName={ApiKeyFields.additional_options}
                  helperText={HELPER_TEXT[ApiKeyFields.additional_options]}
                >
                  {(field) => (
                    <TextAreaField {...field} control={control} maxLength={1500} rows={5} disabled={!watchScheduleEarlier} />
                  )}
                </FormGridFieldItem>
              </Grid>
            </FormAccordion>
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <FormGridFieldItem label={""} fieldName={ApiKeyFields.date_confirmation} hideLabelGrid inputGrid={{ md: 11 }}>
          {(field) => <CheckboxField {...field} control={control} label={FORM_LABELS[ApiKeyFields.date_confirmation]} />}
        </FormGridFieldItem>
      </FormBlock>
    </StepFlowController>
  );
};

export default ReleaseDate;
