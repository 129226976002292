import { ApiKeyFields } from "types";

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.name]: "Publisher Name",
  [ApiKeyFields.rightsAdministrator]: "Rights Administrator",
  [ApiKeyFields.affiliation]: "Affiliation",
  [ApiKeyFields.country]: "Country",
  [ApiKeyFields.split]: "Split",
};

export const HELPER_TEXT = {
  [ApiKeyFields.split]: "A percentage can have 2 decimal places. E.g. “33.34”",
} as Record<ApiKeyFields, string>;
