import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.genre]: "Genre",
  [ApiKeyFields.sub_genre]: "Sub-Genre (Optional)",
  [ApiKeyFields.territory]: "Territory",
};

export const HELPER_TEXT = {
  [ApiKeyFields.territory]: "Territory of distribution for the release.",
} as Record<ApiKeyFields, string>;

export const GENRE_TERRITORY_SIDEBAR_FIELDS = [
  { field: ApiKeyFields.genre, required: true },
  { field: ApiKeyFields.sub_genre, required: false },
  { field: ApiKeyFields.territory, required: true },
] as SubRouteFieldProps[];
