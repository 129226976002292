import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

export const DeselectBtnStyled = styled(Typography)`
  background: none;
  width: fit-content;
  border: none;
  cursor: pointer;
  align-self: end;

  &:disabled {
    color: ${({ theme }) => theme.palette.text.disabled};
    &:hover {
      cursor: not-allowed;
      text-decoration: unset;
    }
  }
  &:hover {
    text-decoration: underline;
  }
` as typeof Typography;
