import React, { FC, useMemo } from "react";

import Grid, { RegularBreakpoints } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export type FormGridItemProps = {
  label: string;
  helperText?: string | React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  inputGrid?: RegularBreakpoints;
  additionalActions?: React.ReactNode;
  hideLabelGrid?: boolean;
};

const FormGridItem: FC<FormGridItemProps> = ({
  label,
  helperText,
  disabled,
  children,
  inputGrid,
  hideLabelGrid,
  additionalActions,
}) => {
  const inputGridOverride = useMemo(() => ({ ...(inputGrid ?? { xs: 12, md: 6 }) }), [inputGrid]);

  return (
    <Grid container spacing={2}>
      {!hideLabelGrid && (
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color={disabled ? "text.disabled" : void 0}
                whiteSpace="pre-line"
                sx={{ pt: "4px", pb: "3px" }}
              >
                {label}
              </Typography>
            </Grid>
            {helperText ? (
              <Grid item xs={12}>
                {typeof helperText === "string" ? (
                  <Typography variant="body1" color="text.label" whiteSpace="pre-line" component="div">
                    {helperText}
                  </Typography>
                ) : null}
                {typeof helperText !== "string" ? helperText : null}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      )}
      <Grid item {...inputGridOverride} display="flex" flexDirection="column" gap="15px">
        {children}
      </Grid>
      {additionalActions}
    </Grid>
  );
};

export default FormGridItem;
