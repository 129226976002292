import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import pickBy from "lodash/pickBy";

import { ApiKeyFields, SongwritersForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils/transformObjects";

export const prepareRequest = (
  formData: SongwritersForm,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
) => {
  const filteredData = pickBy(formData, (_, key) => dirtyFields[key]);
  const shouldIncludeNameOrCountry = dirtyFields[ApiKeyFields.name] || dirtyFields[ApiKeyFields.country];
  if (shouldIncludeNameOrCountry) {
    filteredData[ApiKeyFields.name] = formData[ApiKeyFields.name] ?? null;
    filteredData[ApiKeyFields.country] = formData[ApiKeyFields.country] ?? null;
  }
  return replaceEmptyAndUndefinedWithNull(filteredData);
};
