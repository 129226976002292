import { FC } from "react";
import { useFormContext } from "react-hook-form";

import Grid from "@mui/material/Grid";

import { FormBlockGridGap, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";

import { FORM_FIELDS, FORM_LABELS, HELPER_TEXT } from "./constants";

const WorkForm: FC = () => {
  const { control } = useFormContext();
  return (
    <Grid container gap={FormBlockGridGap}>
      {FORM_FIELDS.map((fieldName) => (
        <FormGridFieldItem
          key={fieldName}
          fieldName={fieldName}
          label={FORM_LABELS[fieldName]}
          helperText={HELPER_TEXT[fieldName]}
        >
          {(field) => <TextField {...field} control={control} />}
        </FormGridFieldItem>
      ))}
    </Grid>
  );
};

export default WorkForm;
