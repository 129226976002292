import {
  FC,
  // MouseEvent,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import some from "lodash/some";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  FormBlock,
  FormBlockGridGap,
  SelectCheckboxField,
  SelectField,
  // ToggleButton
} from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { MUSIC_GENRE_OPTIONS } from "constants/release";
import { TERRITORY_VALUES, TERRITORY_WITH_CODE_OPTIONS } from "constants/release";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT } from "./constants";
import { getSubGenres } from "./helpers";
import { DeselectBtnStyled } from "./styled";

const GenreTerritory: FC<StepFlowControllerProps> = (props) => {
  const { isAdmin } = props;
  const { control, watch, setValue } = useFormContext();

  const [watchGenre, watchSubGenre, watchTerritory] = watch([ApiKeyFields.genre, ApiKeyFields.sub_genre, ApiKeyFields.territory]);

  const isTerritorySelected = Boolean(watchTerritory?.length);

  const [subGenreOptions, setSubGenreOptions] = useState<LabelValue[]>([]);

  useEffect(() => {
    if (watchGenre) {
      const options = getSubGenres(watchGenre);
      setSubGenreOptions(options);
      setValue(ApiKeyFields.sub_genre, some(options, { value: watchSubGenre }) ? watchSubGenre : "", {
        shouldValidate: true,
        shouldDirty: false,
      });
    } else {
      setSubGenreOptions([]);
    }
  }, [watchGenre]);

  const handleSelectStatusClick = () => {
    setValue(ApiKeyFields.territory, isTerritorySelected ? [] : TERRITORY_VALUES, { shouldValidate: true, shouldDirty: true });
  };

  const isSubGenreLength = !subGenreOptions.length;

  return (
    <StepFlowController {...props}>
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem label={FORM_LABELS[ApiKeyFields.genre]} fieldName={ApiKeyFields.genre}>
            {(field) => <SelectField {...field} control={control} options={MUSIC_GENRE_OPTIONS} />}
          </FormGridFieldItem>
          <FormGridFieldItem
            label={FORM_LABELS[ApiKeyFields.sub_genre]}
            fieldName={ApiKeyFields.sub_genre}
            disabled={isSubGenreLength}
          >
            {(field) => <SelectField {...field} control={control} options={subGenreOptions} disabled={isSubGenreLength} />}
          </FormGridFieldItem>
          <FormGridFieldItem
            label={FORM_LABELS[ApiKeyFields.territory]}
            helperText={HELPER_TEXT[ApiKeyFields.territory]}
            fieldName={ApiKeyFields.territory}
          >
            {(field) =>
              // remove duplication
              isAdmin ? (
                <SelectCheckboxField
                  {...field}
                  control={control}
                  options={TERRITORY_WITH_CODE_OPTIONS}
                  renderValue={(value) => (
                    <Typography variant="body1" color="text.primary">
                      {Array.isArray(value) && `${value.length} Selected Territories`}
                    </Typography>
                  )}
                />
              ) : (
                <>
                  <SelectCheckboxField
                    {...field}
                    control={control}
                    options={TERRITORY_WITH_CODE_OPTIONS}
                    renderValue={(value) => (
                      <Typography variant="body1" color="text.primary">
                        {Array.isArray(value) && `${value.length} Selected Territories`}
                      </Typography>
                    )}
                  />
                  <DeselectBtnStyled
                    variant="body1"
                    color="primary.main"
                    textAlign="end"
                    component="button"
                    onClick={handleSelectStatusClick}
                    disabled={field.readOnly}
                  >
                    {isTerritorySelected ? "Deselect All" : "Select All"}
                  </DeselectBtnStyled>
                </>
              )
            }
          </FormGridFieldItem>
        </Grid>
      </FormBlock>
    </StepFlowController>
  );
};

export default GenreTerritory;
